<template>
  <div class="wrapper">
    <tree-to-vliew></tree-to-vliew>
    <create-template :uploadStatus="uploadStatus">
      <edit-info :editInfoList="editInfoList" @submitfn="submitForm"></edit-info>
    </create-template>
  </div>
</template>

<script>
import { isPhoneNum,isNumber } from "@/utils/validate";
import UploadImg from "@/components/UploadImg";
import { addInformation } from "@/api";
import CreateTemplate from "../commonViews/CreateTemplate";
import TreeToVliew from "../commonViews/TreeToVliew";
import EditInfo from "@/views/BaseInfo/editInfo";
export default {
  name: "supervision",
  components: {
    EditInfo,
    TreeToVliew,
    CreateTemplate,
    UploadImg,
  },
  methods: {
    filterData() {
      let data = {};
      Object.keys(this.form).forEach((item) => {
        data[item] = this.form[item];
      });
      return data;
    },
    async submitForm(form) {
      try {
        if (!isPhoneNum(form.people_number)) {
          throw new Error("手机格式错误");
        }
        // if (!isNumber(form.sentries_number)) {
        //   throw new Error("监督岗总人数必须为正整数");
        // }
        // if (!isNumber(form.party_number)) {
        //   throw new Error("党员人数必须为正整数");
        // }
        // if (!isNumber(form.party_positive_number)) {
        //   throw new Error("入党分子必须为正整数");
        // }
        // if (!isNumber(form.peasant_number)) {
        //   throw new Error("农民工人数必须为正整数");
        //}
        delete form.id;
        const requestData = {
          type: 3,
          value: form,
        };
        const res = await addInformation(requestData);
        if (res.status == 200) {
          this.$success("添加成功");
          this.$router.back();
        }
      } catch (e) {
        if (e instanceof Error) {
          this.$message(e.message);
        }
      }
    },
  },
  data() {
    return {
      uploadStatus:{
        name:"sentry"
      },
      editInfoList: [
        { name: "党员质量监督岗名称", field: "name" },
        { name: "设立时间", field: "found_time" },
        // { name: "监督岗总人数", field: "sentries_number" },
        { name: "岗长", field: "people_name" },
        // { name: "党员人数", field: "party_number" },
        // { name: "入党积极分子", field: "party_positive_number" },
        // { name: "农民工人数", field: "peasant_number" },
        { name: "岗长联系电话", field: "people_number" },
      ],
      form: {
        img: "",
        name: "",
        found_time: "",
        people_name: "",
        phone_number: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: #f8f8f8;
  display: flex;
  height: 100%;
}
</style>
